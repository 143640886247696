input[type='checkbox'] {
  height: 20px;
  width: 20px;
  position: absolute;
  opacity: 0;
}
input[type='checkbox'] + span {
  position: relative;
}
input[type='checkbox'] + span::before {
  content: '';
  border: 1px solid #000;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: -4px;
}
input[type='checkbox']:checked + span::before {
  background: #1d4690;
}
input[type='checkbox']:checked + span::after {
  content: '\2713';
  position: absolute;
  left: 4px;
  top: 0px;
  line-height: 20px;
  font-weight: 700;
  color: white;
}

input[type='radio'] {
  height: 18px;
  width: 18px;
  position: absolute;
  opacity: 0;
  margin-left: 3px;
  margin-top: 2px;
}
input[type='radio'] + span {
  position: relative;
}
input[type='radio'] + span::before {
  content: '';
  border: 1px solid #000;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: -4px;
  left: 0;
}

input[type='radio']:checked + span::after,
input[type='radio'].custom-radio:checked + span::after {
  content: '';
  position: absolute;
  left: 3px;
  top: 5px;
  height: 12px;
  width: 12px;
  background: #1d4690;
  border-radius: 50%;
}
input#specialRadio:checked + span::after {
  /* Custom styles for the special radio button */
  content: '';
  position: absolute;
  left: 3px;
  top: 3px;
  height: 12px;
  width: 12px;
  background: #1d4690;
  border-radius: 50%;
}
.canvas-wrapper {
  position: relative;
  width: 400px;
  height: 200px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  width: 400px;
  height: 200px;
  background-color: white;
}

.btn-container--on {
  border: 1px solid green;
  color: green;
}

.btn-container--off {
  border: 1px solid red;
  color: red;
}

.btn-switch {
  position: relative;
  display: block;
  width: 32px;
  height: 15px;
  cursor: pointer;
  background-color: #d8534f;
  border: 2px solid #d33f3a;
  border-radius: 40px;
}

.btn-switch-circle {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 40px;
}

.btn-switch--on {
  background-color: #5cb95c;
  border: 2px solid #4dae4c;
}

.btn-switch-circle--on {
  left: auto;
  right: 0;
}

.autocomplete-popup {
  .ant-select-item-option {
    padding: 0px !important;
  }

  @media (min-width: 640px) {
    inset: 79.8281px 12.5% auto auto !important;
  }

  @media (min-width: 768px) {
    inset: 79.8281px 10.5% auto auto !important;
  }

  @media (min-width: 1024px) {
    inset: 79.8281px 7.8% auto auto !important;
  }

  @media (min-width: 1140px) {
    inset: 79.8281px 7% auto auto !important;
  }
  @media (min-width: 1420px) {
    inset: 79.8281px 16.3% auto auto !important;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.carrier-input {
  padding: 0.35rem;
}

.autocomplete-container {
  position: relative;
}

.suggestions-container {
  position: absolute;
  width: 100%;
  z-index: 1000;
  border: 1px solid #ddd;
  background-color: white;
}

.suggestion-divider {
  margin: 0;
  border: 0;
  border-top: 1px solid #ddd;
}

.carrier-dashboard-input::-webkit-inner-spin-button,
.carrier-dashboard-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.origin-input {
  margin-right: 100px;
}
.destination-input {
  margin-right: 78px;
}

input[type='checkbox'].invoice-checkbox {
  opacity: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid;
  cursor: pointer;
}

input[type='checkbox'].invoice-checkbox:checked {
  background-color: #1d4690; /* Replace with your desired color */
}

input[type='checkbox'].invoice-checkbox:checked::before {
  content: '\2713';
  display: block;
  color: white; /* Customize the checkmark color */
  text-align: center;
  line-height: 19px; /* Adjust based on checkbox size */
  font-size: 16px; /* Adjust based on checkbox size */
}

input[type='radio'].collapsible-radio {
  opacity: 1;
  position: inherit;
  cursor: pointer;
  accent-color: #1d4690;
}

input[type='radio'].quickQuote-radio {
  opacity: 1;
  position: inherit;
  cursor: pointer;
  accent-color: #1d4690;
  height: 16px !important;
  width: 16px !important;
}

input[type='checkbox'].service-options-checkbox {
  position: relative;
  opacity: 1;
  height: 16px !important;
  width: 16px !important;
  cursor: pointer;
  accent-color: #1d4690;
}

.quickQuote-description {
  flex-shrink: 1;
  max-width: calc(
    100% - 20px
  ); /* Adjust this value to match the width of the radio button and gap */
  word-wrap: break-word;
}
